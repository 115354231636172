import React from 'react';
import { isFuture } from 'date-fns';
import { linkResolverRichtext } from './linkResolverRichtext';
import debounce from 'lodash/debounce';
import { Link, navigate } from 'gatsby';
import { encode as base64_encode } from 'base-64';
import { titleCombination } from './titleCombination';
import { getVariantById } from '../services/productVariant';
import { PUSHCHASE_METHOD } from '../constants';
export const loaded = typeof window !== 'undefined';

export function getParameterByName(name) {
  if (loaded) {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return undefined;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  return '';
}

/**
 * debounce lodash
 */
export const debounceCall = debounce(
  (event, newValue) => {
    event(newValue);
  },
  300,
  { leading: false, trailing: true }
);

/**
 * cn
 */
export function cn(...args) {
  return args.filter(Boolean).join(' ');
}

/**
 * map edges to nodes
 */
export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}

/**
 * map nodes to data
 */
export function mapNodesToData(data) {
  if (!data.nodes) return [];
  return data.nodes.map((node) => node.data);
}

/**
 * filter out docs without slugs
 */
export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

/**
 * filter out docs
 */
export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt);
}

/**
 * get model url
 */
export function getModelUrl(prefix = '', slug, convert = false) {
  if (convert) slug = convertToSlug(slug || '');
  return `/${prefix}/${slug?.current || slug}/`;
}

/**
 * @param {*} text
 * @returns
 */
export function convertToSlug(text) {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

/**
 * get image product
 */
export function getImageProduct(data, size = 'w800') {
  if (!data.edges) return '';
  data = data.edges.map((edge) => edge.node);
  return (data[0] && data[0][size]) || '';
}

/**
 * build image obj
 */
export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

/**
 * to plain text
 */
export function toPlainText(blocks) {
  if (!blocks) {
    return '';
  }
  return blocks
    .map((block) => {
      if (block._type !== 'block' || !block.children) {
        return '';
      }
      return block.children.map((child) => child.text).join('');
    })
    .join('\n\n');
}

/**
 * parse query string
 */
export const parseQueryString = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

/**
 * serialize hyperlink
 */
export const serializeHyperlink = (type, element, content, children, index) => {
  if (element.data.link_type === 'Document') {
    return (
      <Link to={linkResolverRichtext(element.data)} key={element.data?.id}>
        {children}
      </Link>
    );
  }

  if (element.data.link_type === 'Web') {
    return (
      <Link to={element?.data?.url?.replace('https://www.activeskin.com.au', '')}>{children}</Link>
    );
  }
};

/**
 * Formats a currency according to the user's locale
 * @param {number} value The amount to format
 * @param {string} currency The ISO currency code
 *
 * @returns
 */
export const formatPrice = (value, currency = 'AUD') =>
  Intl.NumberFormat('en-AU', {
    currency,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(value);

/**
 * get Currency Symbol
 */
export const getCurrencySymbol = (currency, locale = undefined) => {
  if (!currency) {
    return;
  }

  const formatter = Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
  });
  const parts = formatter.formatToParts(100);
  const { value: symbol } = parts.find((part) => part.type === 'currency');
  const formatted = formatter.format(100);
  const symbolAtEnd = formatted.endsWith(symbol);
  return { symbol, symbolAtEnd };
};

/**
 * html to text
 * @param {*} html
 * @returns
 */
export function strip(html) {
  if (typeof DOMParser !== 'undefined') {
    let doc = new DOMParser().parseFromString(html || '', 'text/html');
    return doc?.body?.textContent || '';
  }
  return '';
}

/**
 * set cookie
 * @param {string} cname
 * @param {string} cvalue
 * @param {number} exdays
 */
export function setCookieHasDomain(cname, cvalue, exdays = 1) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie =
    cname + '=' + cvalue.trim() + ';' + expires + ';domain=.activeskin.com.au;path=/';
}

/**
 * set cookie
 * @param {string} cname
 * @param {string} cvalue
 * @param {number} exdays
 */
export function setCookie(cname, cvalue, exdays = 1) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

/**
 * get cookie
 * @param {string} cname
 * @returns
 */
export function getCookie(cname) {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

/**
 * check cookie
 * @param {string} name
 */
export function checkCookie(name) {
  return getCookie(name).trim() != '';
}

export function textCapitalize(str = '', toLowerCase = true) {
  if (toLowerCase) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const configOkeReview = () => {
  if (!document.getElementById('okendo-reviews')) {
    const script = document.createElement('script');
    script.id = 'okendo-reviews';
    // script.src = 'https://d3hw6dc1ow8pp2.cloudfront.net/reviews-widget-plus/js/okendo-reviews.js';
    script.src = 'https://cdn-static.okendo.io/reviews-widget-plus/js/okendo-reviews.js';
    // script.async = true;

    document.head.appendChild(script);
  }
};

export const getOriginByUrl = (url) => {
  if (!url) return false;
  url = url.trim().replace('https://', '').split('/');

  return 'https://' + url[0];
};

export const getLinkToByUrl = (url) => {
  if (!url) return false;
  url = url.trim().replace('https://', '').split('/');

  return url.length <= 1 ? '/' : '/' + url[1];
};

export const getImgCropShopify = (url, size, position = 'crop_center', extention = 'webp') => {
  if (!url) return url;
  const index = url?.lastIndexOf('/') + 1;
  const index2 = url?.lastIndexOf('.');

  let path = url.substring(0, index);
  let name = url.substring(index, index2);
  let last_name = url.substring(index2);

  name += `_${size}_${position}`;

  const arr = last_name.split('?');
  if (arr.length > 0) {
    arr[0] += `.${extention}`;
  }

  last_name = arr.join('?');

  const newName = name + last_name;

  return path + newName;
};

export const formatNameSubBrand = (name, brand) => {
  const _name = name.toLowerCase();
  const _brand = brand.toLowerCase();

  if (_name.indexOf(_brand) === 0) {
    return name.substring(_brand.length + 1).trim();
  }
  return name;
};

export const removeItemEmpty = (items, keyEmptyRemove) => {
  if (Array.isArray(items)) return items.filter((item) => item?.[keyEmptyRemove]);
  return [];
};

export const filterHasFinancialOffers = (offers, product) => {
  try {
    return offers
      ?.filter(({ display_on_product_page }) => display_on_product_page)
      ?.filter(({ brand, category }) => {
        let isMatched = false;

        if (brand || category) {
          if (brand?.title_collection) {
            isMatched =
              product?.collections?.findIndex(
                ({ title }) =>
                  title.indexOf('Brands/') === 0 &&
                  titleCombination(brand?.title_collection?.replace('Brands/', ''), true).includes(
                    title
                  )
              ) !== -1;
          } else if (category?.title_collection) {
            isMatched =
              product?.collections?.findIndex(
                ({ title }) =>
                  title.indexOf('Brands/') === -1 &&
                  titleCombination(category?.title_collection).includes(title)
              ) !== -1;
          }
        } else {
          // show all if rule is not have brand or category condition.
          isMatched = true;
        }
        return isMatched;
      });
  } catch (error) {
    console.log('Function filterHasFinancialOffers has error:', error);
    return [];
  }
};

export const filterHasFreeGift = (rules, product) => {
  try {
    return rules
      ?.filter(
        ({ display_on_product_page, free_gift_group }) =>
          display_on_product_page && free_gift_group?.length
      )
      ?.filter(({ brand, category, products }) => {
        let isMatched = false;

        if (brand || category) {
          if (brand?.title_collection) {
            isMatched =
              product?.collections?.findIndex(
                ({ title }) =>
                  title.indexOf('Brands/') === 0 &&
                  titleCombination(brand?.title_collection?.replace('Brands/', ''), true).includes(
                    title
                  )
              ) !== -1;
          } else if (category?.title_collection) {
            isMatched =
              product?.collections?.findIndex(
                ({ title }) =>
                  title.indexOf('Brands/') === -1 &&
                  titleCombination(category?.title_collection).includes(title)
              ) !== -1;
          }
        } else if (products && products.length > 0) {
          isMatched = products.findIndex((id) => id === product.shopifyId) !== -1;
        } else {
          // show all if rule is not have brand or category condition.
          isMatched = true;
        }
        return isMatched;
      });
  } catch (error) {
    console.log('Function filterHasFreeGift has error:', error);
    return [];
  }
};

export const checkExpried = (start_time, end_time) => {
  if (!start_time && !end_time) return false;
  const now = new Date();
  let _start_time, _end_time;
  if (!start_time) {
    _end_time = new Date(end_time);
    if (now > _end_time) return true;
    return false;
  } else if (!end_time) {
    _start_time = new Date(start_time);
    if (_start_time > now) return true;
    return false;
  } else {
    _start_time = new Date(start_time);
    _end_time = new Date(end_time);

    if (_start_time > now || now > _end_time) return true;
    return false;
  }
};

export const getJsonMetaFeildValue = (metafield) => {
  if (!metafield) return {};
  try {
    return JSON.parse(metafield);
  } catch (error) {
    return {};
  }
};

export const checkProductHasCollection = (collections = [], fields, value) => {
  if (Array.isArray(value)) {
    return collections?.findIndex((collection) => value?.includes(collection?.[fields])) !== -1;
  }
  return collections?.findIndex((collection) => collection?.[fields] === value) !== -1;
};

export const formatDatetime = (date) => {
  var d = new Date(date),
    dformat =
      [
        d.getFullYear(),
        d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1,
        d.getDate() < 10 ? `0${d.getDate()}` : d.getDate(),
      ].join('-') +
      ' ' +
      [
        d.getHours() < 10 ? `0${d.getHours()}` : d.getHours(),
        d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes(),
        d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds(),
      ].join(':');
  return dformat;
};

export const overwriteHidePrice = (vendor, hide_price, purchase_method) => {
  if (hide_price === 'false') hide_price = false;
  if (vendor === 'EyEnvy') {
    if (!hide_price && purchase_method === 'Add To Cart') return false;
    return true;
  }

  return hide_price;
};

export function validateAddToCart(addVariantToCart, checkout, product, variant_id) {
  getVariantById(variant_id, true).then(({ data }) => {
    const { inventoryPolicy, inventoryQuantity, product: _product } = data?.productVariant || {};
    if (_product?.status && _product?.status !== 'ACTIVE') {
      navigate(getModelUrl('buy', product.handle));
      return;
    }

    const res = {
      purchase_method: _product?.purchase_method?.value || null,
      hide_price: overwriteHidePrice(
        product?.vendor,
        _product?.hide_price?.value == 'true',
        _product?.purchase_method?.value
      ),
      inventoryPolicy: inventoryPolicy === 'CONTINUE',
      inventoryQuantity: inventoryQuantity > 0 ? inventoryQuantity : 0,
      no_dispatch: _product?.no_dispatch?.value || '',
    };

    const isGiftCards = product?.productType === 'Gift Cards';
    const comingSoon =
      res.inventoryQuantity === 0 &&
      inventoryQuantity &&
      (res.no_dispatch.indexOf('business days') !== -1 ||
        ['Pre-Order', 'Ready'].includes(res.no_dispatch));
    const isRidirectDetail = product?.collections
      ? checkProductHasCollection(product?.collections, 'title', [
          'Free Gifts',
          'Special Price',
          'Special Gifts',
        ])
      : product?.isRidirectDetail;

    const item = checkout.lineItems.find((v) => v.variant.id === variant_id);

    if (
      res.hide_price ||
      PUSHCHASE_METHOD?.includes(
        res?.purchase_method ||
          (!isGiftCards && !res.inventoryQuantity && !res.inventoryPolicy) ||
          isRidirectDetail
      )
    ) {
      navigate(getModelUrl('buy', product.handle));
    } else if (isGiftCards) {
      addVariantToCart(product, variant_id, 1);
    } else if (
      res.inventoryQuantity > 0 &&
      res.inventoryQuantity - Number(item?.quantity || 0) >= 1
    ) {
      addVariantToCart(product, variant_id, 1, false, 'Ready to dispatch');
    } else if (res.inventoryPolicy || comingSoon) {
      let status = 'Dispatches in 3 - 5 business days';
      if (res.no_dispatch) {
        if (res.no_dispatch.indexOf('business days') !== -1) {
          status = `Dispatches in ${res.no_dispatch}`;
        } else status = res.no_dispatch;
      }
      addVariantToCart(product, variant_id, 1, false, status);
    } else {
      navigate(getModelUrl('buy', product.handle));
    }
  });

  return null;
}
